import React from "react"
import Button from "../components/button"
import { ArrowLeftCircle } from "react-feather"

export default function NotFound() {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen">
            <div className="text-center">
                <h1 className="text-3xl mb-6">404 - Page Not Found</h1>

                <div>
                    <div class="flex justify-center">
                        <Button to="/" size="lg">
                            <ArrowLeftCircle className="w-5 h-5 mr-2"/> Back to Home
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}