import React from "react"
import { Link } from "gatsby"

export default function Button(props)
{
    const sizes = {
        sm: "px-4 py-2 font-thin text-sm",
        md: "px-4 py-2 font-normal text-base",
        lg: "px-6 py-4 font-semibold text-lg",
        xl: "px-8 py-6 font-bold text-xl",
    }

    const types = {
        primary: "bg-app-600 hover:bg-app-700 focus:outline-none focus:border-app-700 active:bg-app-700",
        secondary: "bg-app-500 hover:bg-app-600 focus:outline-none focus:border-app-700 active:bg-app-700",
    }

    return (
        <Link
            {...props}
            className={`${sizes[props.size ?? 'md']} ${types[props.type ?? 'primary']} flex items-center justify-between leading-6 font-medium rounded-lg text-white shadow transition ease-in-out duration-150`}
        />
    )
}